span.label{
	position: relative;
	display: inline-block;
	width: 100%;
	margin: 0.75em 0 0;
	span{
		position: absolute;
		left: 0;
		top:50%;
		transform: translateY(-50%);
		user-select: none;
		display: inline-block;
		height: 100%;
		line-height: 1.6em;
		width: 1.75em;
		text-align: center;
		user-select: none;
		padding: 0 0 0 0.25em;
		&.plus{
			left: auto;
			right: 0;
			padding: 0 0.25em 0 0;
		}
	}

	input{
		padding: 0.25em 1em;
		margin: 0;
		font-size: 1em;
	}
}
input[type="number"], button{
	-webkit-appearance: none;
	border: 0.125rem solid #000;
	padding: 0.25em;
	&:focus{
		// outline: $lighter-brand-color solid 1px;
	}

}
input[type="number"]{
	text-align: center;
	&::-webkit-inner-spin-button, 
	&::-webkit-outer-spin-button { 
		-webkit-appearance: none; 
		margin: 0; 
	}
	&:focus{
		outline: none;
		
	}
}
button{
	padding: 0.25em .5em;
	border: 0.125rem solid #000;
	display: inline-block;
	background: #fff;
	&:hover{
		background: #000;
		color: #fff;
	}
	&:focus{
		outline: none;
	}

}
input{
	width: 100%;
	margin: 0.75em 0 0;
}
form{
	padding: 2em 1em;
	.team_title{
		margin-top:0.125rem;
	}
	@media screen and (min-width: $bp){
		padding: 3em 1em;
		max-width: 800px;
		box-sizing: content-box;
		margin: 0 auto;
	}
}
.rangeOut{
	text-transform: capitalize;
	color: $brand-color;
	margin: .25em 0 0;
    display: inline-block;
}



.outWrapper{
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;

	justify-content: center;
	align-items: center;
	display: none;
	z-index: 99;
	// padding: 0 5%;
	// @media screen and (min-width: $bp){
	// 	padding: 0 30%;
	// }
	&.active{
		display: flex;
	}
	.outputDiv{
		
		box-shadow: $box-shadow;
		height: 0%;
		padding-bottom: 100%;
		background: #fff;
		border: 0.125rem solid #000;
		position: relative;
		transition: all 0.4s linear 0s;
		width: 90%;
		height: 0;
		padding-bottom: 90%;
		position: relative;
		max-width: 600px;
		max-height: 600px;
		overflow: hidden;
		@media screen and (min-width: $bp){
			width: 40vw;
			height: 40vw;
			max-width: none;
			max-height: none;
			padding-bottom: 0%;
		}
		span{
			position: absolute;
			top: 0.5em;
			width: 100%;
			text-align: center;
			z-index: 3;
			line-height: 1;
			span{
				position: static;
			}
			&.bottom{
				top: auto;
				bottom: 0.5em;
			}
		}
		div{
			width: 100%;
			padding-bottom: 0;
			padding-top: 0.25rem;
			height: 0;
			// border-top: 0.125rem solid #000;
			bottom: 0;
			right: 0;
			// transform: translateY(100%);
			position: absolute;
			// background: $brand-color;
			background: $brand-color;
			
		}
	}
}



input[type="range"] {
	cursor: url('../images/cursors/link.png') 33 0, pointer;
}