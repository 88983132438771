.tabs{
	padding: 0 1rem;
	.button{
		height: 1.5em;
		line-height: 1.5em;
		width: 1.5em;
		text-align: center;
		display: inline-block;
		border: 0.125rem solid #000;
		border-radius: 50%;
		margin: 0 0 1rem;

		&:hover{
			background: #000;
			color: #fff;
		}

	}
	.read-more, .read-less{
		text-align: center;
		padding: 0 0 1rem;
		span{
			padding: 0.25em .5em;
			border: 0.125rem solid #000;
			display: inline-block;
			&:hover{
				background: #000;
				color: #fff;
			}
		}
	}
	.read-less{
		padding: 0 0 3rem;
	}
}

.tab, .tab_sub{
	padding: 0 0;
	& + .tab, & + .tab_sub{
		padding: 0.75em 0 0;
	}
	&_title{
		text-align: center;
		font-size: 1.5em;
		text-transform: uppercase;
		padding: 0 0 0.75rem;

		p{
			hyphens: auto;
		}
	}
	&_buttons{
		font-size: 2em;
		display: flex;
		-webkit-flex-flow: row wrap;
		justify-content: space-around;
	}
	&_content{
		text-align: center;
	}
	&.hidden{
		// display: none;
	}
	&.active{
		.button, .read-more{
			display: none;
		}
	}
}
.tab{
	&_content{
		padding: 0 0 1em;
	}
}
.tab_sub{
	display: none;
	
	.tab.active &{
		display: block;
	}
	.tab_sub_content{
		display: none;
		padding: .5em 0 0;
	}
	.tab_sub_title{
		font-size: 1em;
		padding: 0;
		&:hover{
			color: $brand-color;
		}
	}
	&.active{
		color: $brand-color;
		.tab_sub_content{
			display: block;
		}
	}
}
.tab_buttons{
	display: none;
	.tabs.active &{
		display: flex;
	}
	.button{
		border-color: transparent;
		&.active{
			border-color: #000;
		}
	}
}