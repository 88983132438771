@font-face {
    font-family: 'joanna';
    src: url('fonts/joanna-mt-webfont.eot');
    src: url('fonts/joanna-mt-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/joanna-mt-webfont.woff2') format('woff2'),
         url('fonts/joanna-mt-webfont.woff') format('woff'),
         url('fonts/joanna-mt-webfont.ttf') format('truetype'),
         url('fonts/joanna-mt-webfont.svg#joanna_mtregular') format('svg');
    font-weight: normal;
    font-style: normal;
	font-size: 120%;
	unicode-range: U+00-FF;
}

body{
	font-family: Helvetica, Arial, sans-serif;
	font-size: 24px;
	line-height: 1.25;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	color: $color;
	@media screen and (min-width: $bp){
		font-size: 32px;
	}
}

.brand{
	font-family: 'joanna';
	font-size: 1.23em;
	color: $brand-color;
	line-height: 1;
}
p{
	margin: 0;
	width: 100%;
	display: inline-block;
	vertical-align: top;
	&.anim-hover{
		width: auto;
	}
	& + p{
		margin: 1rem 0 0;
		@media screen and (min-width: $bp){
			margin: 1em 0 0;
		}
	}
}
h1, h2, h3{
	font-size: 1.5em;
	line-height: 1;
	margin: 0.5em 0;
	font-weight: normal;
}
blockquote{
	margin: 1em 0 1em 2em;
}
a{
	color: $brand-color;
	text-decoration: none;
	&:hover{
		text-decoration: none;
		color: $brand-color;
	}
}
.team_details a{
	color: $color;
	&:hover{
		text-decoration: none;
		color: $brand-color;
	}
}
.\--contact a{
	color: #000;
	&:hover{
		color: #fff;
	}

}
.m-h{
	display: none;
	@media screen and (min-width:$small-bp){
		display: inline;
	}
}