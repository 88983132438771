@import '../../bower_components/normalize-css/normalize';

@import 'settings';
@import 'typography';
@import 'base';

@import 'modules/accordion';
@import 'modules/tabs';
@import 'modules/landing';
@import 'modules/team';
@import 'modules/anim';
@import 'modules/form';
// @import 'modules/range';

@import 'mixins';


input[type=range]{
    @include input-type-range();
}