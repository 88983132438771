*{
	box-sizing: border-box;
}

body, 
html, 
.wrapper, 
.column, 
.resizer{
	@media screen and (min-width: $bp){
		height: 100%;	
	}
	
}

::selection {
    color: white;
    background: $brand-color;
}
body{
	user-select: none;
}
body, 
html, 
.wrapper{
	overflow: hidden;
	width: 100%;
	height: 100%;
	@media screen and (min-width: $bp){
		
		width: 100%;
	}
}

.wrapper.blurred{
	filter: blur(2px);
	opacity: 0.5;
}
body, input{
	cursor: url('../images/cursors/default.png') 0 0, auto;
}
a, .title,
.title span, 
.read-more span, 
.tab_sub_title p, 
.button,
span.animation,
p.anim-hover, p.anim-hover span.brand
.team_title,
.team_thumb, button, .label span,
.outWrapper, .team_title{
	cursor: url('../images/cursors/link.png') 33 0, pointer;	
	// cursor: pointer;
}
body.dragging,
.resizer{
	@media screen and (min-width: $bp){
		// cursor: url('../images/cursors/DOUBLE_A_B.png') 37 14, move;	
		// cursor: col-resize;
	}
}

.\--contact{
	h2{
		margin-top: .125rem;

	}
	p.name{
		text-transform: uppercase;
	}
}

.landscape_overlay{
	display: none;
}
@media (max-width: #{$bp - 1}) and (orientation: landscape) {
	.landscape_overlay{
		position: fixed;
		height: 100%;
		width: 100%;
		color: #000;
		background: $brand-color;
		top: 0;
		left: 0;
		padding: 1em 1em;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		z-index: 9;
		.brand{
			color: #000;
		}
	}
}