.team{
	text-align: center;
	padding: 0.125rem 0 2em;
	&_title{
		user-select: none;
		margin-top: 0;
		padding: 0 1rem;
		text-transform: uppercase;
	}
	&_thumb{
		
		width: 100%;
		justify-content: center;
		padding: 0 0 1em;
		@media screen and (min-width: $bp){
			display: flex;
			position: fixed;
			height: 100%;
			top: 0;
			left: 0;
			align-items: center;
			pointer-events: none;
			display: none;
			z-index: 990;
			padding: 0 0 0;
			&.thumb{
				display: flex;
			}
		}
		
		img{
			width: 100%;
			height: auto;
			max-height: none;
			display: block;
			box-shadow: $m-box-shadow;
			@media screen and (min-width: $bp){
				width: auto;
				height: auto;
				max-width: 50%;
				max-height: 60%;
				box-shadow: $box-shadow;
				display: inline-block;
			}
		}
	}
	&_content{
		display: none;
	}
	&_details{
		span{
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	&.active{
		.team_content{
			display: block;
			padding: 1rem 0;
		}	
	}
	

}