$brand-color: #13bdc6;
// $lighter-brand-color: #d8eff0;
$lighter-brand-color: #13bdc6;
$color: #111;

$breakpoint: 960px;
$bp: $breakpoint;
$small-bp: 360px;
$box-shadow: rgba(0, 0, 0, 0.25) 0px 18px 30px 8px;
$m-box-shadow: rgba(0, 0, 0, 0.25) 0px 9px 30px 8px;


// The draghandle
$thumb-color: #fff;;
$thumb-radius: 0;
$thumb-height: 2em;
$thumb-width: .5em;
$thumb-border-width: 0.125rem;
$thumb-border-color: #000;
$thumb-shadow-size: 0;
$thumb-shadow-blur: 1px;
$thumb-shadow-color: rgba(0, 0, 0, 0) !default;

// The range
$track-color: #000;
$track-radius: 0;
$track-width: 100% !default;
$track-height: 0.0625em;
$track-border-width: 0px !default;
$track-border-color: #000 !default;
$track-shadow-size: 0 !default;
$track-shadow-blur: 0 !default;
$track-shadow-color: rgba(0, 0, 0, 0) !default;