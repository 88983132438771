/*
	This is where the landing animations are styled
*/

.inner.animations{
	padding: 1em;
	.central, .landing{
		height: 100%;
		width: 100%;
		position: relative;
		overflow: hidden;
		align-items: center;
		justify-content: center;
	}
	.central{
		display: none;;
		
		padding: 1rem;
		position: relative;
		&.active{
			display: block;
		}
		.anim{
			position: absolute;
			height: 100%;
			width: 100%;
			display: none;
			align-items: center;
			top: 0;
		    left: 0;
		    padding: 2rem;
			&.active{
				display: flex;
			}
		}
	}
	.landing{
		// min-width: 600px;
		display: none;
		svg{
			width: 100%;
			height: 100%;
		}
		&.active{
			display: flex;
		}
	}
}
.anim_el{
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	line-height: 1em;
	width: 1em;
	display: block;
	text-align: center;
	opacity: 0;
	transition: opacity 0.2s linear 0s;
	&.visible{
		opacity: 1;
	}
	// background: #000;
	// font-size: 2em;
}
.anim_text_holder{
	display: none;
	font-size: 2rem;
}
text{
	opacity: 0;
	transition: opacity 0.5s linear 0s;
	&.active{
		opacity: 1;
	}
}
@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

span.animation{
	color: $brand-color;
	&.inactive{
		color: inherit;
	}
}