.column{
	
	position: relative;
	overflow: hidden;
	display: block;
	width: 100%;
	float: left;
	z-index: 2;
	// transition: all 0.05s linear 0s;
	img, canvas{
		max-width: 100%;
		max-height: 100%;
		height: auto;
		width: 100%;
	}
	&.\--brand{
		background: $lighter-brand-color;
		a{
			&:hover{
				color: #fff;
			}
		}
	}
	.inner{
		overflow: auto;
	    // overflow-x: hidden;
		height: 100%;
		padding: 1rem;
		-webkit-overflow-scrolling: touch;
		@media screen and (min-width: $bp){
			padding: 1rem 1.25rem 1rem 1.5rem;	
		}
	}
	&.narrow{

		.inner{
			padding: 1rem 0 1.5rem;
			& > *{
				hyphens: auto;
				hyphenate-limit-chars: 3 1 1;
			}
		}
	}
	
	.resizer{
		position: absolute;
		top: 0;
		left: 0;
		background: #000;
		width: 100%;
		height: 0.125rem;
		@media screen and (min-width: $bp){
			width: 0.125rem;
			height: 100vh;
			
		}
		// &:after{
		// 	content: '';
		// 	position: fixed;
		// 	top: 0;
		// 	margin-left: -.7rem;
		// 	width: 1.6rem;
		// 	height: 100%;
		// 	z-index: 2;
		// }
	}
	&.open{
		// flex-basis: 100%;
	}
	&.closed{
		width: 100%;
		height: 0;
		overflow: hidden;
		@media screen and (min-width: $bp){
			width: 0;
			height: 100%;
			overflow: hidden;
			padding: 0;
		}
	}
	&.\--title{
		padding-top: 3rem;
		@media screen and (min-width: $bp){
			padding-top: 0;
			padding-left: 3rem;	

		}
		
		.inner{
			padding-top: 1.5rem;
			@media screen and (min-width: $bp){
				padding-top: 1rem;
				padding-left: .5rem;

			}
		}
		&.open{
			.inner{
				padding-right: 1rem;
				@media screen and (min-width: $bp){
					padding-right: 3rem;
					
				}

			}
		}

		.title{
			position: absolute;
			top: 0.125rem;
			left: 0;
			height: 3rem;
			width: 100%;
			font-size: 2rem;
			@media screen and (min-width: $bp){
				left: 0;
				top: 0;
				width: 3rem;
				height: 100%;
				padding-left: 0.125rem;
				&.\--wider{
					left: 0;
				}
			}
			& > span{
				padding-left: 1rem;
				line-height: 3rem;
				@media screen and (min-width: $bp){
					display: block;
					transform: rotate(90deg);
					transform-origin: left top;
					
					text-transform: capitalize;
					position: absolute;
					top: 1.25rem;
					left: 3rem;
					padding-left: 0;
					white-space: nowrap;
				}
			}
		}
		&.\--title-hidden{
			&.closed{
				padding-top: 3rem;
				&.dragging{
					padding-top: 0rem;
				}
				.title{
					display: block;
				}
			}
			&.open{
				padding-top: 0;
				.title{
					display: none;
				}
				.inner{
					padding-top: 1rem;
				}
			}
			&.dragging{
				padding-left: 0rem;
				.title{
					display: none;
				}
			}
			@media screen and (min-width: $bp){
				&.open{
					padding-left: 1rem;
				}
			}
		}
	}
}


.anim{
	position: fixed;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;

	justify-content: center;
	align-items: center;
	pointer-events: none;
	display: none;
	z-index: 99;
	&.active{
		display: flex;
	}
	img, .canvas{
		width: auto;
		height: auto;
		max-width: 60%;
		max-height: 75%;
		box-shadow: $box-shadow;
		background: #fff;
		border: 0.125rem solid #000;
	}
	.canvas{
		width: 90%;
		height: 0;
		padding-bottom: 90%;
		position: relative;
		max-width: 600px;
		max-height: 600px;
		@media screen and (min-width: $bp){
			width: 40vw;
			height: 40vw;
			max-width: none;
			max-height: none;
			padding-bottom: 0%;
		}
		svg{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}


.\--form{
	text-align: center;
}

@media screen and (min-width: $bp){
.wrapper{
	position: relative;
	&:after{
		content: '';
		height: 100%;
		width: 2rem;
		right: -1rem;
		position: absolute;
		top: 0;
		background: $brand-color;
	  z-index: -1;
	}
}
}


.\--contact{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	p.spacing{
		margin-top: 2em;
	}
}