@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color, 0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin track {
  width: $track-width;
  height: $track-height;
  animation: 0.2s;
  margin: 0;
}

@mixin thumb {
  @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  width: $thumb-width;
  height: $thumb-height;
  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-radius;
  background: $thumb-color;
  transform: translateY(#{(($thumb-height - $track-height)/2) * -1} );
}

@mixin input-type-range {
  -webkit-appearance: none;
  width: $track-width;
  background: transparent;
  height: $thumb-height;
  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    @include track;
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
    background: $track-color;
  }

  &::-webkit-slider-thumb {
    @include thumb;
    -webkit-appearance: none;
    margin-top: ((-#{$track-border-width} * 2) + $track-height) / (2 - $thumb-height / 2);
  }

  &:focus::-webkit-slider-runnable-track {
    background: $track-color;
  }

  &::-moz-range-track {
    @include track;
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius;
    background: $track-color;
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-ms-track {
    @include track;
    color: transparent;
    border-width: $thumb-width 0;
    border-color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius * 2;
    background: $track-color;
  }

  &::-ms-fill-upper {
    @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
    border: $track-border-width solid $track-border-color;
    border-radius: $track-radius * 2;
    background: $track-color;
  }

  &::-ms-thumb {
    @include thumb;
  }

  &:focus::-ms-fill-lower {
    background: $track-color;
  }

  &:focus::-ms-fill-upper {
    background: $track-color;
  }
}